/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserPermissionTypes} from '../../../../app/common/constants/userPermissionTypes'

export function AsideMenuMain() {
  const intl = useIntl()
  const dispatch = useDispatch()
  const permissions: any = useSelector<RootState>(
    ({auth}) => auth.userPermission,
    shallowEqual
  ) as any

  const loc = window.location.href

  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any

  return (
    <>
      {permissions?.includes('dashboard.menu') && (
        <AsideMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/general/gen001.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
      )}
      {/* {permissions?.includes('bookings.menu') && (
        <>
          <div data-kt-menu-trigger='click' className='menu-item menu-accordion hover show'>
            <span className='menu-link'>
              <span className='menu-icon'>
                <span className='svg-icon svg-icon-2'>
                  <KTSVG path={'media/icons/duotune/files/fil002.svg'} className='svg-icon-2' />
                </span>
              </span>
              <span className='menu-title'>Bookings</span>
              <span className='menu-arrow'></span>
            </span>

            <div className='menu-sub menu-sub-accordion show' kt-hidden-height='116' style={{}}>
              <div className='menu-item'>
                <Link to='/bookings/list' className='menu-link'>
                  <span className='menu-bullet'>
                    <span className='bullet bullet-dot'></span>
                  </span>
                  <span className='menu-title'>List View</span>
                </Link>
              </div>{' '}
              <div className='menu-item'>
                <Link to='/bookings/planner' className='menu-link'>
                  <span className='menu-bullet'>
                    <span className='bullet bullet-dot'></span>
                  </span>
                  <span className='menu-title'>Planner View</span>
                </Link>
              </div>
            </div>
          </div>
        </>
      )} */}
      <AsideMenuItemWithSub
        to='/bookings'
        title='Bookings'
        icon='/media/icons/duotune/files/fil002.svg'
        fontIcon='bi-person'
        children={
          <>
            <AsideMenuItem
              to='/bookings/list'
              title='List View'
              hasBullet
              fontIcon='bi-person'
            ></AsideMenuItem>
            <AsideMenuItem
              to='/bookings/planner'
              title='Planner View'
              fontIcon='bi-person'
              hasBullet
            ></AsideMenuItem>
          </>
        }
      />
      {permissions?.includes('myDays.menu') && (
        <AsideMenuItem
          to='/day-plans'
          title='Day Plan'
          icon='/media/icons/duotune/general/gen013.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {permissions?.includes('messages.menu') && (
        <AsideMenuItem
          to='/messages'
          title='Messages'
          icon='/media/icons/duotune/communication/com002.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {permissions?.includes('events.menu') && (
        <AsideMenuItem
          to='/events/list'
          title='Events'
          icon='/media/icons/duotune/files/fil002.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {permissions?.includes('activityFeed.menu') && (
        <AsideMenuItem
          to='/activity-feeds'
          title='Activity Feeds'
          icon='/media/icons/duotune/communication/com004.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {permissions?.includes('reports.menu') && (
        <AsideMenuItemWithSub
          to='/reports'
          title='Reports'
          icon='/media/icons/duotune/general/gen005.svg'
          fontIcon='bi-person'
          children={
            <>
              <AsideMenuItem
                to='/reports/activity'
                title='Activity'
                hasBullet
                fontIcon='bi-person'
              ></AsideMenuItem>
              <AsideMenuItem
                to='/reports/occupancy'
                title='Occupancy'
                fontIcon='bi-person'
                hasBullet
              ></AsideMenuItem>
              <AsideMenuItem
                to='/reports/assets-usage'
                title='Asset Usage'
                fontIcon='bi-person'
                hasBullet
              ></AsideMenuItem>
              <AsideMenuItem
                to='/reports/assets-roll-up'
                title='Asset Roll Up'
                fontIcon='bi-person'
                hasBullet
              ></AsideMenuItem>
            </>
          }
        />
      )}
      {permissions?.includes('users.menu') && (
        <AsideMenuItem
          to='/users'
          title='Users'
          icon='/media/icons/duotune/communication/com006.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {permissions?.includes('teams.menu') && (
        <AsideMenuItem
          to='/teams'
          title='Teams'
          icon='/media/icons/duotune/communication/com005.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {permissions?.includes('sites.menu') && (
        <AsideMenuItem
          to='/sites'
          title='Sites'
          icon='/media/icons/duotune/maps/map002.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {permissions?.includes('notices.menu') && (
        <AsideMenuItem
          to='/notices'
          title='Notices'
          icon='/media/icons/duotune/abstract/abs039.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Assets</span>
        </div>
      </div>
      {permissions?.includes('assets.menu') && (
        <AsideMenuItem
          to='/assets/list'
          title='Assets'
          icon='/media/icons/duotune/abstract/abs027.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {permissions?.includes('assetTypes.menu') && (
        <AsideMenuItem
          to='/asset-types'
          title='Asset Types'
          icon='/media/icons/duotune/art/art002.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {permissions?.includes('attributes.menu') && (
        <AsideMenuItem
          to='/attributes'
          title='Attributes'
          icon='/media/icons/duotune/abstract/abs020.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {permissions?.includes('allocations.menu') && (
        <AsideMenuItem
          to='/allocations'
          title='Allocations'
          icon='/media/icons/duotune/abstract/abs026.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {permissions?.includes('checkInStrategies.menu') && (
        <AsideMenuItem
          to='/checkin-strategies'
          title='Checkin Strategies'
          icon='/media/icons/duotune/arrows/arr035.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {permissions?.includes('neighborhoods.menu') && (
        <AsideMenuItem
          to='/neighborhoods'
          title='Neighborhoods'
          icon='/media/icons/duotune/maps/map006.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {(loc.includes('localhost') || loc.includes('-stage')) && (
        <AsideMenuItem
          to='/planners'
          title='Planners'
          icon='/media/icons/duotune/files/fil002.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {(loc.includes('localhost') || loc.includes('-stage')) && (
        <AsideMenuItem
          to='/charts'
          title='Charts'
          icon='/media/icons/duotune/files/fil002.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Administration</span>
        </div>
      </div>
      {/* {permissions?.includes('notification.menu') && (
        <AsideMenuItem
          to='/notifications'
          title='System Notifications'
          icon='/media/icons/duotune/general/gen007.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )} */}
      {permissions?.includes('publicHoliday.menu') && (
        <AsideMenuItem
          to='/holidays'
          title='Holidays'
          icon='/media/icons/duotune/general/gen014.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {permissions?.includes(UserPermissionTypes.VIEW_AUTOMATIONS) && (
        <AsideMenuItem
          to='/automations'
          title='Automations'
          icon='/media/icons/duotune/abstract/abs036.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {permissions?.includes('userTypes.menu') && (
        <AsideMenuItem
          to='/user-types'
          title='User Types'
          icon='/media/icons/duotune/general/gen049.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {permissions?.includes('vehicles.menu') && (
        <AsideMenuItem
          to='/vehicles'
          title='Vehicles'
          icon='/media/icons/duotune/ecommerce/ecm006.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {permissions?.includes('userSkills.menu') && (
        <AsideMenuItem
          to='/user-skills'
          title='User Skills'
          icon='/media/icons/duotune/general/gen011.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {permissions?.includes('siteTypes.menu') && (
        <AsideMenuItem
          to='/site-types'
          title='Site Types'
          icon='/media/icons/duotune/communication/com009.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {permissions?.includes('noticeTypes.menu') && (
        <AsideMenuItem
          to='/notice-types'
          title='Notice Types'
          icon='/media/icons/duotune/abstract/abs043.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {permissions?.includes('notWorkingReasons.menu') && (
        <AsideMenuItem
          to='/not-working-reasons'
          title='Not Working Reason'
          icon='/media/icons/duotune/medicine/med010.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {permissions?.includes('businessUnit.menu') && (
        <AsideMenuItem
          to='/business-units'
          title='Business Unit'
          icon='/media/icons/duotune/ecommerce/ecm004.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {permissions?.includes('invitations.menu') && (
        <AsideMenuItem
          to='/invites'
          title='Invites'
          icon='/media/icons/duotune/communication/com010.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {permissions?.includes('roles.menu') && (
        <AsideMenuItem
          to='/roles'
          title='Roles'
          icon='/media/icons/duotune/files/fil001.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {permissions?.includes('account.menu') && (
        <AsideMenuItem
          to='/account'
          title='Account'
          icon='/media/icons/duotune/technology/teh004.svg'
          fontIcon='bi-person'
        ></AsideMenuItem>
      )}
      {permissions?.includes('accountThemes.menu') && (
        <AsideMenuItemWithSub
          to='/themes'
          title='Themes'
          icon='/media/icons/duotune/layouts/lay009.svg'
          fontIcon='bi-person'
          children={
            <>
              <AsideMenuItem
                to='/themes/color/list'
                title='Color'
                hasBullet
                fontIcon='bi-person'
              ></AsideMenuItem>
              <AsideMenuItem
                to='/themes/localization/list'
                title='Localization'
                fontIcon='bi-person'
                hasBullet
              ></AsideMenuItem>
            </>
          }
        />
      )}
    </>
  )
}
