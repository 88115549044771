import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import DashboardWrapper from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/users/Users'))
  const TeamsPage = lazy(() => import('../modules/teams/Teams'))
  const InvitesPage = lazy(() => import('../modules/invites/Invites'))
  const MyDaysPage = lazy(() => import('../modules/my-day/MyDays'))
  const SiteTypesPage = lazy(() => import('../modules/site-types/SiteTypes'))
  const SitesPage = lazy(() => import('../modules/sites/Sites'))
  const FloorsPage = lazy(() => import('../modules/floors/Floors'))
  const CheckInStrategiesPage = lazy(
    () => import('../modules/checkin-strategies/CheckInStrategies')
  )
  const AssetTypesPage = lazy(() => import('../modules/asset-types/AssetTypes'))
  const AllocationsPage = lazy(() => import('../modules/allocations/Allocations'))
  const AssetsPage = lazy(() => import('../modules/assets/Assets'))
  const RolesPage = lazy(() => import('../modules/roles/Roles'))
  const NotWorkingReasonsPage = lazy(
    () => import('../modules/not-working-reason/NotWorkingReasons')
  )
  const BusinessUnitsPage = lazy(() => import('../modules/business-unit/BusinessUnit'))
  const AccountPage = lazy(() => import('../modules/account/Account'))
  const PlannersPage = lazy(() => import('../modules/planner/Planners'))
  const NeighborhoodsPage = lazy(() => import('../modules/neighborhoods/Neighborhoods'))
  const AttributesPage = lazy(() => import('../modules/attributes/Attributes'))

  const Messages = lazy(() => import('../modules/messages/Messages'))
  const Charts = lazy(() => import('../modules/charts/Charts'))
  const UserType = lazy(() => import('../modules/user-types/UserType'))
  const ActivityFeeds = lazy(() => import('../modules/activity-feeds/ActivityFeeds'))
  const Notifications = lazy(() => import('../modules/notifications/Notifications'))
  const Holidays = lazy(() => import('../modules/holidays/Holidays'))
  const Bookings = lazy(() => import('../modules/bookings/Bookings'))
  const AccountThemes = lazy(() => import('../modules/account-themes/AccountThemes'))
  const Localizations = lazy(() => import('../modules/account-themes/Localizations'))
  const Events = lazy(() => import('../modules/events/Events'))
  const Reports = lazy(() => import('../modules/reports/Reports'))
  const UserSkills = lazy(() => import('../modules/user-attributes/UserSkills'))
  const NoticeTypes = lazy(() => import('../modules/notice-types/NoticeTypes'))
  const Notices = lazy(() => import('../modules/notices/Notices'))
  const Automations = lazy(() => import('../modules/automations/Automations'))
  const Vehicles = lazy(() => import('../modules/vehicles/Vehicles'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/users' component={UsersPage} />
        <Route path='/teams' component={TeamsPage} />
        <Route path='/invites' component={InvitesPage} />
        <Route path='/site-types' component={SiteTypesPage} />
        <Route path='/checkin-strategies' component={CheckInStrategiesPage} />
        <Route path='/asset-types' component={AssetTypesPage} />
        <Route path='/allocations' component={AllocationsPage} />
        <Route path='/assets' component={AssetsPage} />
        <Route path='/day-plans' component={MyDaysPage} />
        <Route path='/sites' component={SitesPage} />
        <Route path='/floors' component={FloorsPage} />
        <Route path='/roles' component={RolesPage} />
        <Route path='/not-working-reasons' component={NotWorkingReasonsPage} />
        <Route path='/business-units' component={BusinessUnitsPage} />
        <Route path='/account' component={AccountPage} />
        <Route path='/planners' component={PlannersPage} />
        <Route path='/neighborhoods' component={NeighborhoodsPage} />
        <Route path='/attributes' component={AttributesPage} />
        <Route path='/messages' component={Messages} />
        <Route path='/charts' component={Charts} />
        <Route path='/user-types' component={UserType} />
        <Route path='/activity-feeds' component={ActivityFeeds} />
        <Route path='/notifications' component={Notifications} />
        <Route path='/holidays' component={Holidays} />
        <Route path='/bookings' component={Bookings} />
        <Route path='/themes/color' component={AccountThemes} />
        <Route path='/themes/localization' component={Localizations} />
        <Route path='/events' component={Events} />
        <Route path='/reports/activity' component={Reports} />
        <Route path='/reports/occupancy' component={Reports} />
        <Route path='/reports/assets-usage' component={Reports} />
        <Route path='/reports/assets-roll-up' component={Reports} />
        <Route path='/user-skills' component={UserSkills} />
        <Route path='/notice-types' component={NoticeTypes} />
        <Route path='/notices' component={Notices} />
        <Route path='/automations' component={Automations} />
        <Route path='/vehicles' component={Vehicles} />
      </Switch>
    </Suspense>
  )
}
